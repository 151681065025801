<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-01-17 20:11:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
  <div>
    <div class="sld_order_list">
      <!--<MemberTitle :memberTitle="L['采购计划详情']"></MemberTitle>-->
      <div class="container">
        <div style="padding: 0 20px 10px 20px; border: 1px solid #eeeeeeff">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #eeeeeeff;
              padding-bottom: 10px;
            "
          >
            <h3 style="margin-top: 10px; font-size: 16px">
              <!--{{ L["采购计划详情"] }}-->
            </h3>

            <div style="margin-top: 12px; display: flex">
              <el-button @click="goback">返回</el-button>
              <div class="Submit" @click="onSubmit(editFormRef)">提交</div>
            </div>
          </div>
          <div class="sld_order_nav">
            <div class="sld_order_nav_item addBorder distance">
              <p>
                <span class="fontColor">创建时间 :</span>
                {{ topTable.data.createTime }}
              </p>
              <p class="stateStyle">
              <span v-if="platform == 2" :class="topTable.data.state == 0?'yellow':topTable.data.state == 1?'green':topTable.data.state == 2?'bule':topTable.data.state == 3?'gray':''">大石集采</span>
                <span class="yellow" v-if="topTable.data.state == 0">待报价</span>
                <span class="green" v-if="topTable.data.state == 1">报价中</span>
                <span class="bule" v-if="topTable.data.state == 2">已报价</span>
                <span class="gray" v-if="topTable.data.state == 3">已关闭</span>
              </p>
              <p class="UncompletedQuotation">企业未填我的报价</p>
            </div>
            <div class="sld_order_nav_item addBorder distance3">
              <div class="item_title">
                <h3>基本信息</h3>
                <div class="item_type">
                  <p v-if="topTable.data.type == 0">单次采购</p>

                  <p v-if="topTable.data.type == 1">周期采购</p>
                </div>
              </div>
              <p>
                <span class="fontColor">采购单号:</span
                ><span>{{ topTable.data.planCode }}</span>
                <img
                
                  v-if="topTable.data.source == 0"
                 src="@/assets/yongyou.png"
                />
                <img
                  
                  v-if="topTable.data.source == 1"
                  src="@/assets/dashiLogo.png"
                />

                <img
                  v-if="topTable.data.source == 2"
                  
                  src="@/assets/dashijicai@2x.png"
                />
              </p>
               <p>
                <span class="fontColor" v-if="topTable.data.source == 0"
                  >请购单号:</span
                ><span>{{ topTable.data.bizBillCode }}</span>
              </p>
              <p>
                <span class="fontColor">创建人:</span
                ><span style="margin-right: 10px">
                  {{ topTable.data.prayName }} </span
                ><span>
                  {{ topTable.data.operator }}
                </span>
              </p>
              <p>
                <span class="fontColor">创建人部门:</span
                ><span v-if="topTable.data.deptName">
                  {{ topTable.data.deptName }}
                </span>
                <span v-if="!topTable.data.deptName">
                  {{ topTable.data.corpName }}
                </span>
              </p>
              <p>
                <span class="fontColor">备注:</span
                ><span>
                  {{ topTable.data.desc }}
                </span>
              </p>
            </div>
            <div class="sld_order_nav_item distance2">
              <div class="item_title">
                <h3>收货信息</h3>
                <div class="item_title_right">
                  <span class="white" @click="changeAddress">更换地址</span>
                  <span class="addAddress" @click="addAddress">新建地址</span>
                </div>
              </div>

              <div class="content_left flex_column_between_start">
                <p>
                  <span class="fontColor">收货人：</span
                  ><span>{{ topTable.data.receivingName }}</span>
                </p>
                <p>
                  <span class="fontColor">{{ L["联系方式"] }}：</span
                  >{{ topTable.data.receivingMobile }}
                </p>
                <p>
                  <span class="fontColor addressStyle">
                    {{ L["收货地址"] }}:</span
                  ><span>{{
                      topTable.data.receivingAddress
                    }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            padding: 0 20px 15px 20px;
            border: 1px solid #eeeeeeff;
            margin-top: 20px;
            margin-bottom: 24px;
          "
        >
          <div class="goodsList">
            <h3 style="margin-bottom: 10px; margin-top: 10px; font-size: 16px">
              {{ "采购商品" }}
            </h3>
            <el-divider />
            <el-form
              :inline="true"
              :model="tableData.data"
              :rules="rules"
              ref="editFormRef"
            >
              <el-table
                :data="tableData.data"
                style="width: 100%; margin-top: 22px"
                default-expand-all
                ref="multipleTableRef"
                @selection-change="handleSelectionChange"
                border
                :header-cell-style="tableHeaderColor"
              >
                <el-table-column prop="goodsName" label="商品名称" />
                <el-table-column
                  prop="goodsSpec"
                  label="规格型号"
                  align="center"
                  width="120"
                >
                  <template #default="scope">
                    <span v-if="scope.row.goodsSpec">{{ scope.row.goodsSpec }},{{scope.row.goodsMarque}}</span>
                    <span v-if="!scope.row.goodsSpec">{{scope.row.goodsMarque}}</span>
                  </template>
                </el-table-column>
                <!--<el-table-column
                prop="goodsMarque"
                label="型号"
                align="center"
                width="80"
              />-->
                <el-table-column
                  prop="goodsUnit"
                  label="单位数量"
                  align="center"
                  width="120"
                >
                  <template #default="scope">
                    {{ scope.row.purchaseNum }}{{ scope.row.goodsUnit }}
                  </template>
                </el-table-column>
                <!--<el-table-column
                prop="purchaseNum"
                label="采购"
                align="center"
                width="80"
              />-->

                <el-table-column
                  prop="needDate"
                  label="需求日期"
                  align="center"
                  width="120"
                />
                <el-table-column prop="desc" label="备注" align="center" width="310">
                  <template #default="scope">
                    <div style="position: relative;">
                      <el-input
                        v-model="scope.row.desc"
                        :disabled="scope.row.state==3"
                        maxlength="500"
                        style="width: 280px"
                        placeholder="请输入描述"
                        show-word-limit
                        type="textarea"
                      />
                      <span class="clickBtn" v-if="scope.row.purchasePic.length>0" style="position: absolute;top:4px;right: 10px;" @click="addDescImg(scope.row)">查看</span>
                      <i class="el-icon-plus" v-else style="cursor: pointer;font-size: 20px;border: 1px dashed #c0ccda;position: absolute;top:8px;right: 10px;" @click="addDescImg(scope.row)"></i>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="myPrice"
                  label="我的报价"
                  align="center"
                  width="120"
                >
                  <template #default="scope">
                    {{ !scope.row.mySupplierName && !scope.row.myPrice ? "未报价" : scope.row.mySupplierName+' ￥'+scope.row.myPrice}}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="state"
                  label="状态"
                  align="center"
                  width="120"
                >
                  <template #default="scope">
                    <el-tag :type="scope.row.state==0?'primary':scope.row.state==1?'warning':scope.row.state==2?'success':'info'">
                      {{
                        (() => {
                          switch (scope.row.state) {
                            case 0:
                              return '待报价';
                            case 1:
                              return '报价中';
                            case 2:
                              return '已报价';
                            case 3:
                              return '已关闭';
                            default:
                              return '未知状态';
                          }
                        })()
                      }}
                    </el-tag>
                  </template>
                </el-table-column>
              </el-table>
            </el-form>
            <el-divider border-style="dotted" />
            <div class="generateOrder">
              <div class="generateOrder_left">
                <span>
                  <span>
                    <span class="fontColor">采购计划总数:</span>
                    <span>{{ route.query.goodsNum }}</span>件
                  </span>
                  <span>
                    <span class="fontColor" style="margin-left: 20px;">已关闭:</span>
                    <span>{{ route.query.goodsNum==tableData.data.length?0:route.query.goodsNum-tableData.data.length }}</span>件
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<el-dialog
      v-model="dialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      top="13%"
      width="30%"
    >
      <div>
        <div class="my-header">
          <div class="modalTitle"><span>选择支付方式</span></div>
          <div
            style="font-size: 24px; color: rgb(163 158 158); cursor: pointer"
            @click="dialogVisible = false"
          >
            ×
          </div>
        </div>
        <div class="invoice_con flex">
          <div
            class="no_select_invoice flex_row_center_center"
            :class="{ activeLt: index != 0 }"
            v-for="(item, index) in paymentList.data"
            :key="index"
            @click="handlePayment(index)"
          >
            <img :src="selected" v-if="activeIndex == index" alt />
            <img
              :src="not_select"
              v-if="activeIndex != index && item.disabled"
              alt
            />
            <span v-if="item.disabled">{{ item.label }}</span>
            <div
              class="date_box"
              v-show="index == 1 && activeIndex == 1 && orderBelongsVal"
            >
              <el-date-picker
                v-model="selectDate"
                type="date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                placeholder="请选择日期"
                size="small"
              />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div style="display: flex; justify-content: center">
          <div class="Clear" @click="dialogVisible = false">取消</div>
          <div class="Submit2" @click="createOrder">确定</div>
        </div>
      </template>
    </el-dialog>-->

    <el-dialog
      title="更换地址"
      v-model="show_select_address"
      customClass="select_address_width"
      :before-close="handleClose"
      lock-sroll="false"
      width="500"
    >
      <div class="out_stock_dialog address_con">
        <div
          v-for="(item, index) in address_list.data"
          :key="index"
          @click="selectAddress(index)"
          :class="{
            address_item: true,
            flex_column_start_start: true,
            select: current_address_index == index,
          }"
        >
          <span>{{ L["收货人"] }}:{{ item.memberName }}</span>
          <span>{{ L["联系方式"] }}:{{ item.telMobile }}</span>
          <div
            class="address_text"
            :title="item.addressAll + item.detailAddress"
          >
            <span>{{ L["收货地址"] }}:</span>
            <span class="overflow_ellipsis_clamp2"
              >{{ item.addressAll }} {{ item.detailAddress }}</span
            >
          </div>
          <img
            v-if="current_address_index == index"
            class="selected"
            :src="invoice_selected"
            alt
          />
        </div>
      </div>
      <div class="btn_con flex_row_center_center">
        <div
          class="remove red"
          @click="confirmChangeAddress()"
          style="margin-top: 10px"
        >
          确认
        </div>
      </div>
    </el-dialog>
    <SldAddressOperation
      v-if="addressVisibleFlag"
      @close="close"
      @refreshAddress="refreshAddress"
      :visibleFlag="true"
      :getDetailData="getDetailData"
      :type="type"
      :planCode="topTable.data.planCode"
    />
    <!-- 备注图片上传弹窗 -->
      <el-dialog v-model="descImgVisible" :close-on-click-modal="false" :destroy-on-close="true" title="图片信息" width="820px">
        <div>
          <el-upload
            v-model:file-list="itemTableData.data.purchasePic"
            :disabled="itemTableData.data.state==3"
            :action="uploadImgUrl"
            :headers="{ 'Authorization': 'Bearer ' + access_token }"
            list-type="picture-card"
            :before-upload="beforeUpload"
            :on-success="handlePictureCardSuccess(itemTableData.data)"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove(itemTableData.data)"
            :class="{'hide':([...itemTableData.data.purchasePic,...itemAddImgData.data].length >= 5 && itemDelImgData.data.length ==0)||([...itemTableData.data.purchasePic,...itemAddImgData.data].length >= 5 && (itemDelImgData.data.length !=0 && itemDelImgData.data.length >=5)),'disabled-upload': itemTableData.data.state == 3 }"
          >
            <i class="el-icon-plus"></i>
          </el-upload>

          <el-dialog v-model="dialogVisible">
            <img w-full :src="dialogImageUrl" alt="Preview Image" />
          </el-dialog>
        </div>
        <template #footer>
          <div class="dialog-footer">
          <span
            style="text-align: right; display: flex; justify-content: flex-end"
          >
            <div class="Clear"  @click="handleCloseDesc">关闭</div>
            <div class="Submit" type="primary" @click="handleConfirmDesc">确认</div>
          </span>
            <!--<el-button @click="handleCloseDesc">关闭</el-button>
            <el-button  class="Submit" type="primary" @click="handleConfirmDesc">确认</el-button>-->
          </div>
        </template>
      </el-dialog>
  </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";
import SldAddressOperation from "../../../components/PlanAddressOperation.vue";
import {
  ElInput,
  ElMessage,
  ElMessageBox,
  ElTimeline,
  ElTimelineItem,
} from "element-plus";
// import { Search } from '@element-plus/icons-vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiUrlSub } from "../../../utils/config";
import axios from "axios";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
import download from "downloadjs";
import { platform } from "@/utils/config";
export default {
  name: "order-list",
  components: {
    ElInput,
    ElTimeline,
    ElTimelineItem,
    SldCommonEmpty,
    MemberTitle,
    SldAddressOperation,
  },
  setup() {
    // const balance = require("../../assets/buy/balance.png");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const memberInfo = reactive({ data: store.state.memberInfo });
    const { proxy } = getCurrentInstance();
    const addressVisibleFlag = ref(false);
    const L = proxy.$getCurLanguage();
    const access_token = localStorage.getItem('access_token');
    const uploadImgUrl = apiUrlSub + "api/shopp/upload/oss"; //图片上传路径
    const itemTableData = reactive({data:{}}); //table中单项数据
    const itemAddImgData = reactive({data:[]}); //table中单项新增图片数据
    const itemDelImgData = reactive({data:[]}); //table中单项删除图片数据
    const dialogImageUrl = ref('');
    const descImgVisible = ref(false);  //备注图片上传弹窗
    const dialogVisible = ref(false);
    const not_select = require("../../../assets/buy/not_select.png");
    const selected = require("../../../assets/buy/selected.png");
    const invoice_selected = require("../../../assets/buy/invoice_selected.png");
    const current_state = ref(""); //当前列表订单状态
    const evaluate_state = ref(0); //是否是待评价
    const fits = ["fill", "contain", "cover", "none", "scale-down"];
    const orderSn = ref(""); //订单id
    const multipleTableRef = ref();
    const AllMoney = ref(0);
    const show_select_address = ref(false);
    const address_list = reactive({ data: [] });
    const topTable = reactive({ data: [] });
    const orderBelongsVal = ref(false); //是否是三方订单
    const activeIndex = ref(0);
    const selectDate = ref("");
    const editFormRef = ref();
    const payData = reactive({ data: {} }); //会员拥有的支付、付款数据

    const validateNumber = (rule, value, callback) => {
      console.log(value, 900);
      return new Promise((resolve, reject) => {
        if (!value) {
          reject(new Error("请输入报价"));
        } else {
          resolve();
        }
        if (isNaN(value)) {
          reject(new Error("请输入正确报价"));
        } else {
          resolve();
        }
      });
    };
    const rules = {
      myPrice: [
        { required: true, message: "请输入供应商报价", trigger: "blur" },
        {
          validator: validateNumber,
          message: "请输入正确报价",
        },
      ],
      mySupplierName: [
        { required: true, message: "请输入供应商名称", trigger: "blur" },
      ],
    };
    //const paymentList = reactive({
    //  data: [
    //    //企业会员
    //    { label: "预付全款", value: 1, disabled: false },
    //    { label: "货到付款", value: 2, disabled: false },
    //    { label: "账期支付", value: 3, disabled: false },
    //  ],
    //});
    const pageData = reactive({
      pageNum: 1,
      pageSize: 10,
      total: 0,
    });
    const multipleSelection = ref([]);
    const current_address_index = ref(0);
    const type = ref("add");
    //我的供应商输入
    const mySupplierNameHandleChange = (e, item) => {
      console.log(e.target.value, item);
      if (e.target.value) {
        item.borderColor = false;
      }
      if (e.target.value.trim() !== e.target.value) {
        //alert("输入框中不允许包含空格！");
        // 可以选择清除输入框内容或执行其他操作
          item.borderColor = true;
    }
      
    };
    //** 查询会员拥有的支付方式和付款方式 */
    //const getPayWays = () => {
    //  let params = { memberId: store.state.memberInfo.memberId };
    //  proxy
    //    .$get("api/shopp/member/order/member/pay/ways", params)
    //    .then((res) => {
    //      if (res.code == 200) {
    //        payData.data = res.data;
    //      }
    //    });
    //};

    //选择支付方式
    //const handlePayment = (e) => {
    //  activeIndex.value = e;
    //};

    //初始化支付方式
    //const initPayment = () => {
    //  console.log(payData.data);
    //  console.log(multipleSelection.value);
    //  console.log(tableData.data);
    //  let arr = multipleSelection.value;
    //  for (let i = 0; i < arr.length; i++) {
    //    // ** 检查是否是 三方订单
    //    if (arr[i].needSku.orderBelongs == 2) {
    //      orderBelongsVal.value = true;
    //    }
    //  }
    //  let everyVal = arr.every((item) => item.needSku.orderBelongs == 1);
    //  if (everyVal) {
    //    orderBelongsVal.value = false;
    //  }
    //  for (let i = 0; i < arr.length; i++) {
    //    // ** 检查是否支持 预付全款
    //    if (
    //      arr[i].needSku.payWays.includes("0") &&
    //      payData.data.payWays.includes("0")
    //    ) {
    //      paymentList.data[0].disabled = true;
    //      // return ElMessage.warning('当前订单不支持预付全款！')
    //    }
    //    // ** 检查是否支持 货到付款
    //    if (
    //      arr[i].needSku.payWays.includes("2") &&
    //      payData.data.payWays.includes("2")
    //    ) {
    //      paymentList.data[1].disabled = true;
    //      // return ElMessage.warning('当前订单不支持货到付款！')
    //    }
    //    // ** 检查是否支持 账期支付
    //    if (
    //      arr[i].needSku.payWays.includes("3") &&
    //      payData.data.payWays.includes("3") &&
    //      !orderBelongsVal.value
    //    ) {
    //      paymentList.data[2].disabled = true;
    //      // return ElMessage.warning('当前订单不支持账期支付！')
    //    }
    //  }
    //};

    //关闭弹窗
    const close = () => {
      addressVisibleFlag.value = false;
    };
    //弹出新建地址窗口
    const addAddress = () => {
      addressVisibleFlag.value = true;
    };
    const refreshAddress = (addressInfo, addressId) => {
      console.log(addressInfo, addressId);
      tableData.data.receiverAddress = addressInfo.addressAll;
      tableData.data.receivingMobile = addressInfo.telMobile;
      tableData.data.receivingName = addressInfo.memberName;
    };
    const OtherList = reactive({
      data: [],
    });
    const debounce = (fn, time) => {
      let timer = null;
      return (...arges) => {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {}, time);
      };
    };
    const handleChange = (e, item) => {
      if (e.target.value) {
        item.isColor = false;
      }
       if (e.target.value.trim() !== e.target.value) {
        item.isColor = true;
          isTrue = false;
      }
      let input = e.target.value;
      // 只允许输入数字和小数点
      input = input.replace(/[^0-9.]/g, "");
      if (input.includes(".")) {
        let integerPart = input.split(".")[0];
        let decimalPart = input.split(".")[1];

        // 限制小数部分最多为两位，整数部分最多为10位
        if (decimalPart.length > 2) {
          decimalPart = decimalPart.slice(0, 2);
        }

        if (integerPart.length > 10) {
          integerPart = integerPart.slice(0, 10);
        }

        input = integerPart + "." + decimalPart;
      } else {
        // 限制整数部分最多为10位
        if (input.length > 10) {
          input = input.slice(0, 10);
        }
      }
      e.target.value = input;
    };

    const getAddressList = () => {
      let params = { uscUserId: store.state.memberInfo.uscUserId,pageSize: 1000 };
      proxy
        .$get("api/shopp/member/address/list",params)
        .then((res) => {
          if (res.code == 200) {
            address_list.data = res.data;
            console.log(address_list);
          } else {
            confirmOrder(2);
            ElMessage.success(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
    };
    //const pageNums = ref(0);
    const tableData = reactive({
      data: [],
      goodsList: [{ name: 1 }],
      needCount: {},
    });
    let param = reactive({
      memberId: store.state.memberInfo.memberId,
      planCode: "",
      source: "",
      prayName: "",
      startDate: "",
      endDate: "",
      resource: "",
      state: "",
      pageNum: 1,
      pageSize: 10,
    });

    //去商品详情
    const goGoodsDetail = (e) => {
      router.push({
        name: "GoodsDetail",
        query: {
          productId: e,
        },
      });
    };

    //返回
    const goback = () => {
      router.go(-1);
      // router.push({
      //     path: "/member/procurement/procurementPlanList",
      //     query: {
      //       //planCode: code,
      //       //state: state,
      //       pageNum:route.query.pageNum,
      //       pageSize:route.query.pageSize
      //     },
      //   });
    };
    //获取详情数据
    const getDetailData = () => {
      console.log(route.query);
      proxy

        .$get("api/shopp/purchase/plan/queryPlan", {
          planCode: route.query.planCode,
        })
        .then((res) => {
          if (res.code == 200) {
            topTable.data = res.data;
          } else {
            ElMessage(res.msg);
          }
        })
        .catch(() => {
          //异常处理
        });
      //proxy
      //  .$get("api/shopp/purchase/plan/needCount", {
      //    planCode: route.query.planCode,
      //  })
      //  .then((res) => {
      //    if (res.code == 200) {
      //      tableData.needCount = res.data;
      //    } else {
      //      ElMessage(res.msg);
      //    }
      //  })
      //  .catch(() => {
      //    //异常处理
      //  });
    };

    //切换地址
    const selectAddress = (index) => {
      current_address_index.value = index;
    };
    //确认修改地址
    const confirmChangeAddress = () => {
      let param = {
        planCode: topTable.data.planCode,
        receivingName:
          address_list.data[current_address_index.value].memberName,
        receivingMobile:
          address_list.data[current_address_index.value].telMobile,
        receivingAddress: `${
          address_list.data[current_address_index.value].addressAll
        },${address_list.data[current_address_index.value].detailAddress}`,
        receivingAddressCode: `${
          address_list.data[current_address_index.value].provinceCode
        },${address_list.data[current_address_index.value].cityCode},${
          address_list.data[current_address_index.value].districtCode
        },`,
      };
      proxy.$post("api/shopp/purchase/plan/updatePlan", param,"json").then((res) => {
        if (res.code == 200) {
          show_select_address.value = false;
          getDetailData();
        } else {
          ElMessage(res.msg);
        }
      });
      localStorage.setItem(
        "addressId",
        address_list.data[current_address_index.value].addressId
      );
    };
    //弹出地址选择框
    const changeAddress = () => {
      getAddressList();
      show_select_address.value = true;
    };
    //确认修改地址

    
    //其他推荐选中
    //const handleCheckAllChange = (skuCode, needCode, i) => {
    //  let param = {
    //    skuCode,
    //    needCode,
    //  };
    //  proxy
    //    .$get("api/shopp/purchase/plan/editRec", param)
    //    .then((res) => {
    //      if (res.code == 200) {
    //        const index = tableData.goodsList.findIndex(
    //          (item) => item.needCode === i
    //        );
    //        //tableData.goodsList[index].isShow = false;
    //        getDetailData();

    //        //console.log(tableData.goodsList);
    //      } else {
    //        ElMessage(res.msg);
    //      }
    //    })
    //    .catch(() => {
    //      //异常处理
    //    });
    //};
    //生成计划

    //const createOrder = () => {
    //  tableData.data.planDetails = multipleSelection.value;

    //  let data = tableData.data.planDetails.map((item) => {
    //    return (item = { ...item, ...item.needSku });
    //  });
    //  tableData.data.planDetails = data;
    //  tableData.data.planDetails.forEach((item) => {
    //    item.goodsNum = item.purchaseNum;
    //  });
    //  tableData.data.receiverName = tableData.data.receivingName;
    //  tableData.data.receiverMobile = tableData.data.receivingMobile;
    //  tableData.data.receiverAddressCode = tableData.data.receivingAddressCode;
    //  tableData.data.receiverAddress = tableData.data.receiverAddress;
    //  tableData.data.submitType = 1;
    //  tableData.data.memberId = store.state.memberInfo.memberId;
    //  tableData.data.memberName = store.state.memberInfo.memberName;
    //  tableData.data.orderPayType =
    //    activeIndex.value == 1 ? 2 : activeIndex.value == 2 ? 3 : 0;
    //  if (activeIndex.value == 1 && orderBelongsVal) {
    //    tableData.data.arriveDate = selectDate.value;
    //  }
    //  console.log(multipleSelection.value);
    //  console.log(tableData.data);
    //  if (tableData.data.receiverName) {
    //    if (Object.keys(tableData.data.planDetails).length) {
    //      proxy
    //        .$post(
    //          "api/shopp/purchase/plan/createOrder",
    //          tableData.data,
    //          "json"
    //        )
    //        .then((res) => {
    //          if (res.code == 200) {
    //            ElMessage({
    //              message: "操作成功",
    //              type: "success",
    //            });
    //            //** 货到付款 或者 账期支付 或者 是待审批订单
    //            if (
    //              activeIndex.value == 1 ||
    //              activeIndex.value == 2 ||
    //              res.data[0].orderState == 1
    //            ) {
    //              return router.replace({
    //                path: "/member/order/list",
    //              });
    //            }
    //            //** 预付全款 并且 不是待审批订单
    //            let paySn = res.data.map((obj) => obj.paymentNumber);
    //            // return console.log(paySn)
    //            proxy.$sldStatEvent({ behaviorType: "buy" });
    //            router.replace({
    //              path: "/buy/pay",
    //              query: {
    //                paySn: paySn,
    //                payFrom: 1,
    //              },
    //            });
    //            // router.push("/member/index");
    //          } else {
    //            ElMessage(res.msg);
    //          }
    //        })
    //        .catch(() => {
    //          //异常处理
    //        });
    //    } else {
    //      ElMessage({
    //        message: "请选择商品",
    //        type: "warning",
    //      });
    //    }
    //  } else {
    //    return ElMessage({
    //      message: "请填写地址",
    //      type: "error",
    //    });
    //  }
    //};
    //const selectPaymentMethod = () => {
    //  // console.log(multipleSelection.value);
    //  tableData.data.planDetails = multipleSelection.value;
    //  if (Object.keys(tableData.data.planDetails).length) {
    //    initPayment();
    //    activeIndex.value = 0;
    //    dialogVisible.value = true;
    //  } else {
    //    ElMessage({
    //      message: "请选择商品",
    //      type: "warning",
    //    });
    //  }
    //};

    //const getSelectGoods = (array1, array2) => {
    //  const result = [];

    //  for (let i = 0; i < array1.length; i++) {
    //    const searchString = array1[i];
    //    console.log(searchString);

    //    const foundObject = array2.find((obj) => obj.skuCode == searchString);
    //    result.push(foundObject);
    //    console.log(result);

    //    //}

    //    //}
    //  }

    //  return result;
    //};
    const getDetailGoodsData = () => {
      proxy
        .$get("api/shopp/purchase/plan/queryEditPurchaseNeeds", {
          planCode: route.query.planCode,
          state: 0,
        })
        .then((res) => {
          if (res.code == 200) {
            tableData.data = res.data;
            tableData.data.forEach((item) => {
              item.purchasePic?item.purchasePic=item.purchasePic.split(',').map((url,index) => ({ uid:new Date().getTime(),name:index+'img.png',url })):item.purchasePic = [];
              item.isColor = false;
              item.borderColor = false;
            });
          }
        })
        .catch(() => {
          //异常处理
        });
    };

    //** 上传图片之前处理 只能上传图片格式
    const beforeUpload =(file)=> {
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        ElMessage({
          message: '只能上传图片格式文件!',
          type: "error",
        });
      }
      return isImage;
    };

    //** 上传图片
    const handlePictureCardSuccess = (row) => {
      return (res, file, fileList) => {
        let obj={
          uid : '',
          name : '',
          url : ''
        }
        if(res.code==200) {
          obj.uid = file.uid;
          obj.name = file.name;
          obj.url = res.msg;
          itemAddImgData.data.push(obj);
        }
      };
    };

    const handleRemove = (row) => {
      return (uploadFile, uploadFiles) => {
        itemDelImgData.data = uploadFiles;
      };
    };

    const handlePictureCardPreview = (uploadFile) => {
      dialogImageUrl.value = uploadFile.url;
      dialogVisible.value = true
    };

    const addDescImg = (item) =>{
      itemDelImgData.data = [];
      itemAddImgData.data = [];
      itemTableData.data = item;
      setTimeout(() => {
        descImgVisible.value = true;
      }, 1000);
    };

    //关闭备注弹窗
    const handleCloseDesc = () =>{
      descImgVisible.value = false;
      itemTableData.data.purchasePic = [...itemTableData.data.purchasePic];
    };

    //确认备注弹窗
    const handleConfirmDesc = () =>{
      descImgVisible.value = false;
      //if(itemDelImgData.data.length==0 && itemAddImgData.data.length==0) {
      //  itemTableData.data.purchasePic = [];
      //}else 
      if(itemDelImgData.data.length>0) {
        for (let index = 0; index < itemDelImgData.data.length; index++) {
          if(itemDelImgData.data[index].response) {
            itemDelImgData.data[index].url = itemDelImgData.data[index].response.msg
          }
        }
        itemTableData.data.purchasePic = itemDelImgData.data;
      }else{
        itemTableData.data.purchasePic = [...itemTableData.data.purchasePic,...itemAddImgData.data];
      }
    };

    //提交
    const onSubmit = async (formEl) => {
      let params = [];
      for (let index = 0; index < tableData.data.length; index++) {
        let obj = {
          needCode: '',
          desc: '',
          purchasePic: ''
        };
        obj.needCode = tableData.data[index].needCode;
        obj.desc = tableData.data[index].desc;
        obj.purchasePic= tableData.data[index].purchasePic.map(item => item.url).join(',');
        params.push(obj);
      }
      // return console.log(params)
      proxy.$post("api/shopp/purchase/plan/editPurchaseNeeds", params, "json").then((res) => {
        if (res.code == 200) {
          ElMessage({
          message: "操作成功",
          type: "success",
        });
          router.go(-1);
        }else{
          ElMessage({
          message: res.msg,
          type: "error",
        });
        }
      })
      .catch(() => {
        //异常处理
      });
    };
    onMounted(() => {
      if (route.query.orderState) {
        current_state.value = route.query.orderState;
      } else {
        current_state.value = "";
      }
      if (route.query.evaluateState) {
        evaluate_state.value = route.query.evaluateState;
      } else {
        evaluate_state.value = "";
      }

      getDetailData();
      getDetailGoodsData();
    });
    const tableHeaderColor = (data) => {
      return {
        background: "#F6F6F6FF",
        color: "#333333FF",
        fontWeight: "bold",
      };
    };
    return {
      L,
      param,
      tableData,
      itemTableData,
      itemAddImgData,
      itemDelImgData,
      fits,
      multipleTableRef,
      multipleSelection,
      AllMoney,
      OtherList,

      goback,
      addressVisibleFlag,
      addAddress,
      refreshAddress,
      close,
      type,
      show_select_address,
      confirmChangeAddress,
      selectAddress,
      changeAddress,
      tableHeaderColor,
      payData,
      orderBelongsVal,
      selectDate,
      activeIndex,
      access_token,
      uploadImgUrl,
      dialogImageUrl,
      dialogVisible,
      descImgVisible,
      handleCloseDesc,
      handleConfirmDesc,
      addDescImg,
      beforeUpload,
      handlePictureCardSuccess,
      handlePictureCardPreview,
      handleRemove,
      not_select,
      selected,
      invoice_selected,
      current_address_index,
      changeAddress,
      address_list,
      getAddressList,
      getDetailData,
      handleChange,
      goGoodsDetail,
      //toggleSelection
      debounce,
      validateNumber,
      rules,
      topTable,
      getDetailGoodsData,
      onSubmit,
      editFormRef,
      mySupplierNameHandleChange,
      route,
      router,
       platform
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/waitQuotationDetail.scss";

.clickBtn{
  color: $colorMain;
  cursor: pointer;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0e3177 !important;
  border-color: #0e3177 !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #0e3177 !important;
  border-color: #0e3177 !important;
}
.show{
  ::v-deep .el-upload--picture-card{
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.hide{
  ::v-deep .el-upload--picture-card{
    display: none;
  }
}
.disabled-upload {
  ::v-deep .el-upload--picture-card{
    opacity: 0.5; /* 设置透明度使其看起来置灰 */
    cursor: not-allowed !important;
  }
}
.el-overlay .el-dialog__body img{
  width: 100%;
}
::v-deep .el-textarea__inner{
  padding-right: 26px;
}

.sld_member_main_content[data-v-61d93b18] {
  background: #f8f8f8;
}
.sld_order_list .container {
  width: 1207px;
}
.sld_order_list {
  /*width: 1210px;*/
  width: 100%;
  background: #fff;
}
.el-pager li.active {
  color: $colorMain;
  cursor: default;
  font-size: 16px;
}

.el-pager li:hover {
  color: $colorMain;
}

.el-select-dropdown__item.selected {
  color: $colorMain;
}

.sld_order_list {
  color: #000;
  .sld_List {
    color: #000;
  }
  /*.el-dialog__header {
    background-color: $colorMain;
    padding: 11px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-dialog__title {
      color: #fff;
      font-size: 14px;
    }
    .el-dialog__headerbtn {
      top: auto;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff !important;
      font-size: 18px;
    }
  }
  .el-dialog {
    overflow: hidden;
    border-radius: 4px;
  }
  .el-dialog__footer {
    display: flex;
    // justify-content: center;
    align-items: center;
  }*/
  /*.aft_btn:hover {
    background-color: rgba($color: #f30213, $alpha: 0.2);
    color: #f30213;
  }*/
  .aft_btn:focus {
    background-color: #fff;
    color: #333333;
    border-color: #dcdfe6;
  }
  .dia_btn {
    .el-button {
      width: 134px;
      height: 36px;
    }
  }
  .downLoad_contract {
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #e8e8f1;
    border-radius: 5px;
    cursor: pointer;
  }
  .downLoad_contract:hover {
    border: 1px solid $colorMain;
  }
}
.container {
  .bootom-footer {
    text-align: right;
  }
  .bootom-footer button:first-child {
    margin-right: 10px;
  }
  // .el-input {
  //   width: 300px;
  // }
  .unit {
    font-size: 18px;
    margin-left: 10px;
  }
}
.demo-form-inline .el-input {
  --el-input-width: 220px;
}
.el-table thead {
  color: #5f5f60;
}
.el-table {
  color: #2b2b2c;
}
.result {
  height: 100%;
  margin-top: 30px;
  text-align: center;
  padding: 0px 20px 50px 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  margin-bottom: 40px;
}
.child {
  width: 100%;
  display: flex;
  align-content: center;
  .goodsMassage {
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    width: 92%;
    align-items: center;
    .operationContent {
      width: 100%;
      line-height: 2;
      .goodsContent {
        display: flex;
        p {
          width: 30%;
          line-height: 2.5;
        }
      }
    }
  }
  .goodsMassages {
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    width: 90%;
    /*align-items: ;*/
    .operationContent {
      width: 100%;
      line-height: 2;
      .goodsContent {
        display: flex;
        p {
          width: 30%;
          line-height: 2.5;
        }
      }
    }
  }
}
.showContent {
  margin-top: 20px;
  padding: 10px;
  background: rgb(250, 247, 247);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.receive_info {
  border: 1px solid #dddddd;
  border-radius: 2px;
  margin-bottom: 30px;
  .pre_message_title,
  .receive_info_title {
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    padding-left: 20px;
    background-color: #f8f8f8;
    color: #666666;
  }
  .receive_info_content {
    height: 159px;
    padding: 0 40px;
  }

  .pre_message_info {
    padding: 10px 40px;
    .tag_pre {
      display: inline-block;
      line-height: 25px;
      text-align: right;
      font-weight: 400;
      margin-right: 10px;
      font-size: 14px;
      color: #333333;
      display: flex;
      min-width: 100px;
      max-width: 200px;
      justify-content: flex-end;
    }

    strong {
      font-size: 14px;
      color: $colorMain;
      margin-right: 3px;
    }
  }
  .content_left {
    width: 785px;
    /*border-right: 1px solid #dddddd;*/
    .member {
      font-size: 14px;
    }
    .address_detail {
      width: 700px;
    }
    span:not(:first-child) {
      font-size: 14px;
      line-height: 14px;
      margin-top: 22px;
    }
  }
  .content_right {
    width: 370px;
    font-size: 14px;
    border-left: 1px solid #dddddd;
    padding-left: 10px;
    .replace_address {
      color: $colorMain2;
      line-height: 14px;
    }
  }
  .add_address {
    width: 100px;
    height: 30px;
    background-color: $colorMain;
    color: white;
    line-height: 30px;
    text-align: center;
    margin-top: 29px;
    border-radius: 3px;
  }
  .nomartop {
    margin-top: 0;
  }
}
.select_address_width {
  width: 500px !important;
}
.overflow_ellipsis_clamp2 {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;

  line-height: 19px;
}
.out_stock_dialog {
  width: 460px;
  margin: 0 auto;
  height: 330px;
  overflow-y: scroll;
  .good_item {
    font-size: 14px;

    img {
      width: 80px;
      height: 80px;
    }
    .good_info {
      margin-left: 10px;
    }
    .good_name {
      width: 320px;
      color: #333333;
      line-height: 14px;
      margin-top: 10px;
      display: inline-block;
    }
    .spec_num {
      margin-top: 26px;
      .good_spec {
        color: #999999;
      }
      .good_num {
        float: right;
        color: #333333;
      }
    }
  }
}
.address_con {
  height: 330px;
  overflow-y: scroll;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
  .address_item {
    &:not(:first-child) {
      margin-top: 20px;
    }
    width: 458px;
    //    height: 104px;
    box-sizing: border-box;
    border: 1px solid #dfdfdf;
    position: relative;
    padding: 20px;
    span,
    div:not(:first-child) {
      margin-top: 12px;
    }
    .address_text {
      display: flex;
      width: 400px;
    }
    .selected {
      width: 14px;
      height: 14px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .select {
    border: 1px solid $colorMain2;
  }
}
.red {
  background-color: $colorMain;
  color: white;
}
.remove {
  width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 3px;
  background-color: $colorMain;
  margin-left: 10px;
  color: white;
}
.replace_address {
  color: $colorMain2;
  line-height: 14px;
}
.Submit {
  background: #0e3177;
  width: 70.5px;
  height: 38.5px;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  margin-left: 10px;
  line-height: 38.5px;
  font-size: 14px;
}
.tabs {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  color: #fff;
  background: #fff;
  border: 1px solid #cfcfcfff;
  margin-right: 10px;
  font-size: 14px;
}
.btnActive {
  background: #0e3177;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  color: #fff;
  margin-right: 10px;
  font-size: 14px;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e3e2e2;
  padding: 13px 20px 0px 15px;
  background-color: $colorMain;
  color: white;
  padding-bottom: 10px;
}
/*::v-deep .el-dialog__header {
  padding: 0px;
  padding-bottom: 0px;
}*/
.modalTitle {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  img {
    width: 27px;
    height: 24px;
    /*margin-right: 5px;*/
  }
}
.Clear {
  width: 76px;
  height: 38.5px;
  border-radius: 4px;
  text-align: center;
  color: #5e5858;
  line-height: 35px;
  font-size: 14px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.Submit2 {
  background: #0e3177;
  width: 76px;
  height: 35px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  margin-left: 10px;
  line-height: 35px;
  font-size: 14px;
  cursor: pointer;
}
.invoice_con {
  height: 100px;
  display: flex;
  justify-content: center;
  .activeLt {
    margin-left: 20px;
  }
  .no_select_invoice {
    cursor: pointer;
    img {
      width: 18px;
      height: 18px;
    }
    span {
      font-size: 14px;
      line-height: 14px;
      margin-left: 10px;
    }
    .invoice_info {
      margin-left: 50px;
      border-left: 1px solid #dddddd;
      span {
        font-size: 14px;
        line-height: 14px;
        margin-left: 50px;
      }
      .choose {
        color: #257bfd;
        cursor: pointer;
      }
    }
    .block {
      margin-left: 10px;
      ::v-deep .el-date-editor.el-input {
        width: 160px;
      }
      .el-input__inner {
        padding-left: 40px;
      }
    }
    .date_box {
      margin-left: 10px;
      ::v-deep .el-date-editor.el-input {
        width: 160px;
      }
    }
  }
}
</style>